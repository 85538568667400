// import Sidebar from '@Common/Components/Sidebar'
import { Center, Grid, GridItem, Link, useColorMode } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Navbar from '@Common/Components/Navbar'
import ComponentsTheme from '@Theme/Components'

const Container = styled.main`
  max-width: 1980px;
  margin: auto;
`

/**
 * The base layout (almost) all views extend
 *
 * @param {Component} props.children - the main content
 * @param {Boolean} props.withFab - if the view contains a fab button, anm additional bottom padding is added
 */
const BaseLayout = ({ children }) => {
  const { colorMode } = useColorMode()

  return (
    <Grid
      minH="100vh"
      templateAreas={{
        base: `"nav"
               "main"
               "footer"`,
        xl: `"nav main"
             "nav footer"`,
      }}
      gridTemplateRows={{ base: '1fr', xl: '1fr 40px' }}
      gridTemplateColumns={{ base: '1fr', xl: '300px 1fr' }}
      gap="0"
    >
      <GridItem area="nav" bg={ComponentsTheme.navbarSub.bg[colorMode]}>
        <Navbar />
      </GridItem>
      <GridItem area="main" style={{ maxWidth: '100%', overflow: 'hidden' }}>
        <Container size="xl">{children}</Container>
      </GridItem>
      <GridItem
        bg={ComponentsTheme.footer.bg[colorMode]}
        area="footer"
        padding={0}
        alignItems="center"
        justifyContent={'center'}
        display="flex"
      >
        <Center margin={0} paddingY={2}><span>© 2023 villardamond.it</span> <span style={{ margin: '0 .2rem' }}>-</span> <Link color={ComponentsTheme.link.color[colorMode]} href="mailto:info@villardamond.it">info@villardamond.it</Link></Center>
      </GridItem>
    </Grid>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default BaseLayout
