import { Box, SimpleGrid, useMediaQuery } from '@chakra-ui/react'
import CurrentData from 'Realtime/Components/CurrentData'
import TodayChart from 'Realtime/Components/TodayChart'
import { concat, defaultTo } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Panel from '@Common/Components/Panel'
import { toast } from '@Common/Components/Toast'
import BaseLayout from '@Common/Layouts/BaseLayout'
import Logger from '@Common/Utils/Logger'
import config from '@Config'
import GpsMap from '@Dashboard/Components/GpsMap'
import Timelapse from '@Dashboard/Components/Timelapse'
import theme from '@Theme'
import ForecastData from '@Realtime/Components/ForecastData'

const DashboardView = () => {
  const { t } = useTranslation()
  const [imagesUrls, setImagesUrls] = useState([])
  const [isBigScreen] = useMediaQuery(`(min-width: ${theme.breakpoints.md})`)

  Logger.info('DashboardView', 'isBigScreen', isBigScreen)

  useEffect(() => {
    const fetchImagesUrls = async () => {
      try {
        const res = await fetch(`https://cam.torinometeo.org/api/day.php?sid=${config.station.webcamId}`)
        const body = await res.json()
        setImagesUrls(defaultTo([], body.images.SD.map(concat(config.station.webcamBaseUrl))))
      } catch (e) {
        toast({
          title: t(`dashboard:errors.FetchWebcamImagesUrlsError`),
          description: t(`dashboard:errors.FetchWebcamImagesUrlsErrorDescription`),
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
    }

    fetchImagesUrls()
  }, [])

  return (
    <BaseLayout>
      <Box p={5} gap="1rem">
        <SimpleGrid columns={{ sm: 1, md: 1, lg: 2 }} spacing={4}>
          <Timelapse
            imagesUrls={imagesUrls}
            cover="https://villardamond.cam.torinometeo.org/24h/32/last.jpg?891785613"
          />
          <GpsMap lat={44.99993153} lng={6.917948} alt={1750} />
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 1, md: 1, lg: 1 }} spacing={2} margin="1rem 0">
          <Panel title={t('realtime:ui.CurrentData')}>
            <CurrentData />
          </Panel>
          <Panel title={t('realtime:ui.ForecastData')}>
            <ForecastData />
          </Panel>
          <Panel title={t('realtime:ui.TodayMeasures')}>
            <TodayChart />
          </Panel>
        </SimpleGrid>
      </Box>
    </BaseLayout>
  )
}

export default DashboardView
