import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box, Center, Spinner } from '@chakra-ui/react'
import Bg from '@Assets/Images/startup-bg.jpg'
import LogoVertical from '@Common/Components/LogoVertical'

const Wrapper = styled(Box)`
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  color: #fff;
  min-height: 100vh;
`
const StartupView = () => {
  const { t } = useTranslation()

  return (
    <Wrapper display='flex' alignItems='center' flexDir='column' justifyContent='center'>
      <Center flexDir='column' gap={4}>
        <LogoVertical width='250px' color1="#FFBE58" color2="#ffffff" />
        <Spinner size='lg' my={4} />
        {t('ui.Loading')}
      </Center>
    </Wrapper>
  )
}

export default StartupView
