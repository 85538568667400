/* eslint-disable no-unused-vars */
import { Image, SimpleGrid, Stat, StatLabel, StatNumber, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useForecastDataQuery } from 'Realtime/Services/Api'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { FcClock } from 'react-icons/fc'
import { IoCalendarOutline } from 'react-icons/io5'

import { withLoader } from '@Common/Utils/HOF'
import { defaultTo } from 'ramda'

const ForecastData = () => {
  const { t } = useTranslation()
  const { data, isFetching } = useForecastDataQuery({ date: dayjs().format('YYYY-MM-DD') })
  const { data: dataTomorrow } = useForecastDataQuery({ date: dayjs().add(1, 'day').format('YYYY-MM-DD') })
  const { data: dataAfterTomorrow } = useForecastDataQuery({ date: dayjs().add(2, 'day').format('YYYY-MM-DD') })
  const { data: dataAfterAfterTomorrow } = useForecastDataQuery({ date: dayjs().add(3, 'day').format('YYYY-MM-DD') })
  return withLoader(
    () => (
      <Tabs mt={4}>
        <TabList>
          <Tab>{dayjs().format('LL')}</Tab>
          <Tab>{dayjs().add(1, 'day').format('LL')}</Tab>
          <Tab>{dayjs().add(2, 'day').format('LL')}</Tab>
          <Tab>{dayjs().add(3, 'day').format('LL')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={2} margin="2rem 0">
              {data.map((item, index) => (
                <Stat key={index}>
                  <StatLabel display={'flex'} alignItems="center" gap={'.5rem'}>
                    <FcClock size={25} /> {item.period_label}
                  </StatLabel>
                  <StatNumber>
                    <Image src={item.icon} width="90px" />
                  </StatNumber>
                </Stat>
              ))}
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={2} margin="2rem 0">
              {defaultTo([], dataTomorrow).map((item, index) => (
                <Stat key={index}>
                  <StatLabel display={'flex'} alignItems="center" gap={'.5rem'}>
                    <FcClock size={25} /> {item.period_label}
                  </StatLabel>
                  <StatNumber>
                    <Image src={item.icon} width="90px" />
                  </StatNumber>
                </Stat>
              ))}
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={2} margin="2rem 0">
              {defaultTo([], dataAfterTomorrow).map((item, index) => (
                <Stat key={index}>
                  <StatLabel display={'flex'} alignItems="center" gap={'.5rem'}>
                    <FcClock size={25} /> {item.period_label}
                  </StatLabel>
                  <StatNumber>
                    <Image src={item.icon} width="90px" />
                  </StatNumber>
                </Stat>
              ))}
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={2} margin="2rem 0">
              {defaultTo([], dataAfterAfterTomorrow).map((item, index) => (
                <Stat key={index}>
                  <StatLabel display={'flex'} alignItems="center" gap={'.5rem'}>
                    <FcClock size={25} /> {item.period_label}
                  </StatLabel>
                  <StatNumber>
                    <Image src={item.icon} width="90px" />
                  </StatNumber>
                </Stat>
              ))}
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    ),
    isFetching,
  )
}

export default ForecastData
