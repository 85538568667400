import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

// const breakpoints = {
//   sm: '30em',
//   md: '48em',
//   lg: '62em',
//   xl: '80em',
//   '2xl': '96em',
// }

const theme = extendTheme({
  config,
  fonts: {
    heading: `'Roboto Condensed', sans-serif`,
    body: `'Roboto Condensed', sans-serif`,
  },
  styles: {
    global: ({ colorMode }) => {
      return {
        body: {
          color: 'default',
          bg: colorMode === 'dark' ? 'gray.900' : 'white',
        },
      }
    },
  },
})

export default theme
