import { Box, Heading, useColorMode, useMediaQuery } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { compose, F, T } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FcCalendar, FcSettings, FcStatistics, FcHome } from 'react-icons/fc'

import config from '@Config'
import { history } from '@Core/Redux/Store'
import theme from '@Theme'
import ComponentsTheme from '@Theme/Components'

import Logo from './Logo'
import LogoVertical from './LogoVertical'
import SettingsModal from './SettingsModal'

const Navbar = () => {
  const { t } = useTranslation()
  const { colorMode } = useColorMode()
  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false)

  const [isBigScreen] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`)

  const handleOpenSettings = compose(setSettingsModalIsOpen, T)
  const handleCloseSettings = compose(setSettingsModalIsOpen, F)
  const handleGoToForecast = () => history.push(config.urls.forecast)
  const handleGoToHome = () => history.push(config.urls.home)
  const handleGoToDataHistory = () => history.push(config.urls.dataHistory)

  return (
    <>
      <Box
        bg={ComponentsTheme.navbar.bg[colorMode]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={{ base: 2, xl: 10 }}
      >
        <Heading colorScheme="green.100" color="green.400" fontSize={'1.6rem'} display={{ base: 'block', xl: 'none' }}>
          <Logo width="280px" height="80px" color1="#FFBE58" color2="#ffffff" />
        </Heading>
        <Heading colorScheme="green.100" color="green.400" fontSize={'1.6rem'} display={{ base: 'none', xl: 'block' }}>
          <LogoVertical width="280px" height="80px" color1="#FFBE58" color2="#ffffff" />
        </Heading>
        {settingsModalIsOpen && <SettingsModal onClose={handleCloseSettings} />}
      </Box>
      <Box
        alignItems={{ base: 'center', xl: 'flex-start' }}
        justifyContent={{ base: 'center', xl: 'flex-start' }}
        display="flex"
        flexDirection={{ base: 'row', xl: 'column' }}
        gap={{ base: 5, xl: 0 }}
        p={{ base: 2, xl: 0 }}
        color={ComponentsTheme.navbarSub.color[colorMode]}
      >
        <Box
          alignItems={'center'}
          display="flex"
          gap=".5rem"
          onClick={handleGoToHome}
          cursor="pointer"
          py={{ base: 0, xl: 5 }}
          px={{ base: 0, xl: 5 }}
          pt={{ base: 0, xl: 5 }}
          borderBottom={{ base: 'none', xl: '1px solid rgba(255, 255, 255, .1)' }}
          width={{ base: 'auto', xl: '100%' }}
          backgroundColor={window.location.pathname === config.urls.home && isBigScreen ? 'rgba(255, 255, 255, .1)' : 'transparent'}
        >
          <FcHome onClick={handleGoToHome} size={24} />
          <span>{t('ui.Home')}</span>
        </Box>
        <Box
          alignItems={'center'}
          display="flex"
          gap=".5rem"
          onClick={handleGoToDataHistory}
          cursor="pointer"
          py={{ base: 0, xl: 5 }}
          px={{ base: 0, xl: 5 }}
          borderBottom={{ base: 'none', xl: '1px solid rgba(255, 255, 255, .1)' }}
          width={{ base: 'auto', xl: '100%' }}
          backgroundColor={window.location.pathname === config.urls.dataHistory && isBigScreen ? 'rgba(255, 255, 255, .1)' : 'transparent'}
        >
          <FcCalendar onClick={handleGoToDataHistory} size={24} />
          <span>{t('ui.DataHistory')}</span>
        </Box>
        <Box
          alignItems={'center'}
          display="flex"
          gap=".5rem"
          onClick={handleGoToForecast}
          cursor="pointer"
          py={{ base: 0, xl: 5 }}
          px={{ base: 0, xl: 5 }}
          borderBottom={{ base: 'none', xl: '1px solid rgba(255, 255, 255, .1)' }}
          width={{ base: 'auto', xl: '100%' }}
          backgroundColor={window.location.pathname === config.urls.forecast && isBigScreen ? 'rgba(255, 255, 255, .1)' : 'transparent'}
        >
          <FcStatistics onClick={handleGoToForecast} size={24} />
          <span>{t('ui.Forecast')}</span>
        </Box>
        <Box
          alignItems={'center'}
          display="flex"
          gap=".5rem"
          onClick={handleOpenSettings}
          cursor="pointer"
          py={{ base: 0, xl: 5 }}
          px={{ base: 0, xl: 5 }}
          borderBottom={{ base: 'none', xl: '1px solid rgba(255, 255, 255, .1)' }}
          width={{ base: 'auto', xl: '100%' }}
        >
          <FcSettings onClick={handleOpenSettings} size={24} />
          {isBigScreen && <span>{t('ui.Settings')}</span>}
        </Box>
      </Box>
    </>
  )
}

Navbar.propTypes = {
  dashboard: PropTypes.bool,
  forecast: PropTypes.bool,
}

export default Navbar
